<script>
  import About from "./_About.vue";
  import PodcastRowItem from "../podcasts/PodcastRowItem.vue";
  import EpisodeRowItem from "../episodes/EpisodeRowItem.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          founder: [],
          author: [],
        }
      }
    }
  },
  components: {
    About,
    PodcastRowItem,
    EpisodeRowItem,
  },
};
</script>

<template>
  <About v-bind:data="data"></About>

  <div v-if="data.founder && data.founder.length > 0">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between mb-1">
      <h4 class="mb-sm-0">Founder</h4>
    </div>
    <div class="team-list list-view-filter row">
      <div v-for="p in data.founder" v-bind:key="p">
        <PodcastRowItem v-bind:data="p"></PodcastRowItem>
      </div>
    </div>
  </div>

  <div v-if="data.author && data.author.length > 0">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between mb-1" :class="{ 'mt-1' : data.founder.length != 0 }">
      <h4 class="mb-sm-0">Author</h4>
    </div>
    <div class="team-list list-view-filter row">
      <div v-for="p in data.author" v-bind:key="p">
        <PodcastRowItem v-bind:data="p"></PodcastRowItem>
      </div>
    </div>
  </div>

  <div v-if="data.guest && data.guest.length > 0">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between" :class="{ 'mt-1' : data.author.length != 0 }">
      <h4 class="mb-sm-0">Guest</h4>
    </div>
    <div class="team-list list-view-filter row">
      <div v-for="p in data.guest" v-bind:key="p">
        <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
      </div>
    </div>
  </div>
  
</template>